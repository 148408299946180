import * as React from "react";
import { FunctionComponent } from "react";
import SubmissionSuccessPage from "../templates/SubmissionSuccess/SubmissionSuccess";

const ChangeAddressSuccess: FunctionComponent = (): JSX.Element => (
  <SubmissionSuccessPage
    heading="Change of Address Submitted"
    content="Thank you. Your Change of Address request has been received. You will receive an email confirmation once this information has been updated in Hawaiiana Management Company's system."
  />
);

export default ChangeAddressSuccess;
